import { LocalStorageService } from 'src/app/services/localstorage.service';
import { UserSettings } from './../../models/user.settings.model';
import { Component, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { OrderStatus } from "../../enums/order.status.enum";
import { OrderedItem, User } from "../../models";
import { MessageService } from "../../services/message.service";
import { SelfOrderService } from "../../services/self.order.service";
import { SubscriptionService } from "../../services/subscription.service";
import { SignalRService } from "../../services/signalr.service";
import { Subscription } from "rxjs";
import { ModalService } from "../../services/modal.service";
import { ActivatedRoute, Route, Router, RouterEvent } from "@angular/router";
import { HomeService } from "src/app/services/home.service";
import { isSet } from 'lodash';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: "app-root",
  templateUrl: "./self-order.component.html",
  styleUrls: ["./self-order.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SelfOrderComponent implements OnInit, OnDestroy, OnChanges {
  public error: string;
  public message: string;
  public searchText: string;
  public user: User;
  public userSettings: any;
  public showOrderMenu = false;
  public table: any;
  public orderedItems: OrderedItem[];
  public existingNotSentItems: boolean;
  public existingSentItems: boolean;
  public existingServedItems: boolean;
  public selfOrderMainTitle: string;
  public selfOrderSubTitle: string;
  public waiterCalled = false;
  public billRequested = false;

  private waiterCallAcceptedSubscription: Subscription;
  private billRequestAcceptedSubscription: Subscription;

  private audio = undefined;

  @HostBinding("style.--so-primary-color") primaryColor: string = '';
  @HostBinding("style.--so-secondary-color") secondaryColor: string = '';
  @Input() refreshData: boolean = true;

  private menuId: string;
  logoImage: string;
  restaurantName: string;
  isLoading: boolean = true;
  menuNotExists: boolean = true;

  constructor(
    private subscriptionService: SubscriptionService,
    private selfOrderService: SelfOrderService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private signalRService: SignalRService,
    private modalService: ModalService,
    private storage: LocalStorageService,
    private utilsService: UtilsService,
    private route: ActivatedRoute,
    private router: Router,
    private homeService: HomeService) {
    this.menuId = this.route.snapshot.params['menuId'];
    if(this.menuId) {
      localStorage.setItem("menu-id", this.menuId);
    } else {
      var id = localStorage.getItem("menu-id");
      if(id) {
        this.menuId = id;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {    
  }

  public async ngOnInit() {
    this.audio = new Audio();
    this.audio.src = "/assets/sounds/notification.mp3";
    this.audio.load();

    if (this.refreshData) {
      this.homeService.getRestaurantInfo(this.menuId).subscribe(async (response: any) => {
        this.logoImage = response.logoUrl;
        this.restaurantName = response.restaurantName;
        this.primaryColor = response.primaryColor;
        this.secondaryColor = response.secondaryColor;
        localStorage.setItem("customerId", response.customerId);
        
        var selfOrderUser = await this.storage.getSelfOrderUserAsync();
        if(response.shouldRegister) {
          if(selfOrderUser == null) {
            return this.router.navigate(['/so/register']);
          } else if(!selfOrderUser.telefonVerified) {
            return this.router.navigate(['/so/verify']);
          }
        }

        this.homeService.getSelfOrderInfo(this.menuId).subscribe(menu => {
          this.saveData(menu);
          this.loadData();
          this.router.navigate(['/so/no']);
        });
      });
    } else {
      this.loadData();
      var selfOrderUser = await this.storage.getSelfOrderUserAsync();
      if(this.userSettings.shouldRegister) {
        if(selfOrderUser == null) {
          return this.router.navigate(['/so/register']);
        } else if(!selfOrderUser.telefonVerified) {
          return this.router.navigate(['/so/verify']);
        }
      } else {
        this.registerForEvents();
      }      
    }
  }

  registerForEvents() {
    this.waiterCallAcceptedSubscription = this.signalRService.waiterCallAccepted.subscribe(async (tableId) => {
      this.waiterCallAccepted();
    });

    this.billRequestAcceptedSubscription = this.signalRService.billRequestAccepted.subscribe(async (tableId) => {
      this.billRequestAccepted();
    });
  }

  ngOnDestroy(): void {
    this.waiterCallAcceptedSubscription?.unsubscribe();
    this.billRequestAcceptedSubscription?.unsubscribe();
  }

  saveData(menu: any) {
    this.menuNotExists = menu.menuNotExists;
    localStorage.setItem("menu-not-exists", this.menuNotExists + '');

    if (!this.menuNotExists) {
      
      if(!menu.userSettings.selfOrderMainColor) {
        menu.userSettings.selfOrderMainColor = "#9CC434";
      }

      if(!menu.userSettings.selfOrderSecondaryColor) {
        menu.userSettings.selfOrderSecondaryColor = "#B41E4C";
      }

      menu.userSettings.selfOrderMainColor = this.toCssColor(menu.userSettings.selfOrderMainColor);
      menu.userSettings.selfOrderSecondaryColor = this.toCssColor(menu.userSettings.selfOrderSecondaryColor);

      const r = document.querySelector(':root') as any;
      menu.userSettings.selfOrderSecondaryColor = this.toCssColor(menu.userSettings.selfOrderSecondaryColor);
            r.style.setProperty('--so-primary-color', menu.userSettings.selfOrderMainColor);
            r.style.setProperty('--so-primary-inverted-color', this.utilsService.invertColor(menu.userSettings.selfOrderMainColor, true));
            r.style.setProperty('--so-secondary-color', menu.userSettings.selfOrderSecondaryColor);
            r.style.setProperty('--so-secondary-inverted-color', this.utilsService.invertColor(menu.userSettings.selfOrderSecondaryColor, true));

      for(var product of menu.products) {
        if(product.color) product.color = this.toCssColor(product.color);
      }

      for(var category of menu.categories) {
        if(category.color) category.color = this.toCssColor(category.color);
      }

      localStorage.setItem("ordered-items", JSON.stringify(menu.orderedItems));
      localStorage.setItem("products", JSON.stringify(menu.products));
      localStorage.setItem("product-pictures", JSON.stringify(menu.productPictures));
      localStorage.setItem("categories", JSON.stringify(menu.categories));
      localStorage.setItem("category-pictures", JSON.stringify(menu.categoryPictures));
      localStorage.setItem("extras", JSON.stringify(menu.extras));
      localStorage.setItem("side-dishes", JSON.stringify(menu.sideDishes));
      localStorage.setItem("user-settings", JSON.stringify(menu.userSettings));
      localStorage.setItem("table", JSON.stringify(menu.table));
      localStorage.setItem("self-order-user", JSON.stringify(menu.user));
      localStorage.setItem("user-logo", menu.user.logoUrl);
      localStorage.setItem("is-self-order", "true");
      localStorage.setItem("customerId", menu.customerId);
      localStorage.setItem("currencySymbol", menu.user.currencySymbol);
      localStorage.setItem("web-push-id", menu.webPushId);
      localStorage.setItem("onesignal-app-id", menu.oneSignalAppId);
      localStorage.setItem("special-access-token", menu.specialAccessToken);
    }

    setTimeout(() => this.isLoading = false, 1000);
  }

  public showMenu() {
    this.subscriptionService.openSelfOrderMenu(this.orderedItems);
  }

  public openOrderList() {
    this.subscriptionService.openSelfOrderList();
  }

  public callWaiter() {
    this.selfOrderService.callWaiter(this.user["partitionKey"], this.table.id, this.table.name).subscribe(() => {
      localStorage.setItem("WaiterCalled", "true");
      this.waiterCalled = true;
      this.modalService.hide("confirm-dialog");

      const confirmModel = {
        message: this.translateService.instant("WaitersAreInformed"),
        icon: "check",
        type: "info",
        hideCancelButton: true,
        confirmButtonTitle: "Close",
        confirmed: () => {
          this.modalService.hide("confirm-dialog");
        },
      };
      this.modalService.show("confirm-dialog", confirmModel);
    }, (err) => {
      const confirmModel = {
        message: this.translateService.instant(err),
        icon: "times",
        type: "danger",
        hideCancelButton: true,
        confirmButtonTitle: "Close",
        confirmed: () => {
          this.modalService.hide("confirm-dialog");
        },
      };
      this.modalService.show("confirm-dialog", confirmModel);
    });
  }

  public requestBill() {
    const servedItems = this.orderedItems.filter((i) => i.status === OrderStatus.served || i.status === OrderStatus.ready);
    if (servedItems.length === 0) {
      const confirmModel = {
        message: this.translateService.instant("YouHaveNoServedOrders"),
        icon: "times",
        type: "danger",
        hideCancelButton: true,
        confirmButtonTitle: "Close",
        confirmed: () => {
          this.modalService.hide("confirm-dialog");
        },
      };
      this.modalService.show("confirm-dialog", confirmModel);
      return;
    }

    this.selfOrderService.requestBill(this.user["partitionKey"], this.table.id, this.table.name).subscribe(() => {
      localStorage.setItem("BillRequested", "true");
      this.billRequested = true;
      this.modalService.hide("confirm-dialog");

      const confirmModel = {
        message: this.translateService.instant("WaitersAreInformedForBillRequest"),
        icon: "check",
        type: "info",
        hideCancelButton: true,
        confirmButtonTitle: "Close",
        confirmed: () => {
          this.modalService.hide("confirm-dialog");
        },
      };
      this.modalService.show("confirm-dialog", confirmModel);
    }, (err) => {
      const confirmModel = {
        message: this.translateService.instant(err),
        icon: "times",
        type: "danger",
        hideCancelButton: true,
        confirmButtonTitle: "Close",
        confirmed: () => {
          this.modalService.hide("confirm-dialog");
        },
      };
      this.modalService.show("confirm-dialog", confirmModel);
    });
  }

  public async ordersUpdated(): Promise<any> {
    await this.loadData();
    this.audio.play();
  }

  public getLogo() {
    return "/assets/images/smartkasse-logo.png";
  }

  private async loadSavedOrderedItems(): Promise<OrderedItem[]> {
    let items = localStorage.getItem("self-orders");
    if (items) {
      return JSON.parse(items);
    }
    return [];
  }

  private async loadData(): Promise<any> {
    this.menuNotExists = false;
    this.user = JSON.parse(localStorage.getItem("self-order-user"));
    this.table = JSON.parse(localStorage.getItem("table"));
    this.userSettings = JSON.parse(localStorage.getItem("user-settings"));
    this.selfOrderMainTitle = this.userSettings.selfOrderMainTitle;
    this.selfOrderSubTitle = this.userSettings.selfOrderSubTitle;

    this.logoImage = this.user.logoUrl;
    this.restaurantName = this.user.restaurantName;
    this.primaryColor = this.userSettings.selfOrderMainColor;
    this.secondaryColor = this.userSettings.selfOrderSecondaryColor;
    
    this.waiterCalled = localStorage.getItem("WaiterCalled") == "true";
    this.billRequested = localStorage.getItem("BillRequested") == "true";

    this.orderedItems = await this.loadSavedOrderedItems();
    const newItems = this.orderedItems.filter((i) => i.status === OrderStatus.new);
    this.existingNotSentItems = newItems.length > 0;

    const cookingItems = this.orderedItems.filter((i) => i.status === OrderStatus.cooking);
    this.existingSentItems = cookingItems.length > 0;

    const servedItems = this.orderedItems.filter((i) => i.status === OrderStatus.ready);
    this.existingServedItems = servedItems.length > 0;

    this.selfOrderService.isWaiterAlreadyCalled(this.user["partitionKey"], this.table.id).subscribe(result => {
      if (!result && this.waiterCalled) {
        this.waiterCallAccepted();
      }
    }, err => handleError(err));

    this.selfOrderService.isBillAlreadyRequested(this.user["partitionKey"], this.table.id).subscribe(result => {
      if (!result && this.billRequested) {
        this.billRequestAccepted();
      }
    }, err => handleError(err));

    setTimeout(() => this.isLoading = false, 1000);
  }

  private waiterCallAccepted() {
    localStorage.removeItem("WaiterCalled");
    this.waiterCalled = false;
    this.audio.play();
    this.modalService.hide("confirm-dialog");

    const confirmModel = {
      message: this.translateService.instant("WaiterCallAccepted"),
      icon: "check",
      type: "info",
      hideCancelButton: true,
      confirmButtonTitle: "Close",
      confirmed: () => {
        this.modalService.hide("confirm-dialog");
      },
    };
    this.modalService.show("confirm-dialog", confirmModel);
  }

  private billRequestAccepted() {
    localStorage.removeItem("BillRequested");
    this.billRequested = false;
    this.modalService.hide("confirm-dialog");
    this.audio.play();

    const confirmModel = {
      message: this.translateService.instant("WaiterCallAccepted"),
      icon: "check",
      type: "info",
      hideCancelButton: true,
      confirmButtonTitle: "Close",
      confirmed: () => {
        this.modalService.hide("confirm-dialog");
      },
    };
    this.modalService.show("confirm-dialog", confirmModel);
  }

  private toCssColor(color: String): String {
    if(color.length == 9) {
      var transparency = color.substring(1, 3);
      var colorOnly = color.substring(3);

      return "#" + colorOnly + transparency;
    }

    return color;
  }
}

function handleError(err: any): void {
  if(err == "ScanCodeAgain") {
    location.href = '/so/scan';
  }
}

